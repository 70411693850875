import { Component, OnInit, Input } from '@angular/core';
import { TranslationService } from 'src/app/shared/translation.service';
import {ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  @Input() data:any;

  blog:Array<any> = [
    {title: this.translate.data.product.all},
    {title: this.translate.data.product.machine},
    {title: this.translate.data.product.robot},
  ];

  articles:Array<any> = [1,2,3,4];
  responsiveOptions:any;

  constructor(
    public translate: TranslationService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.responsiveOptions = [
      {
          breakpoint: '1024px',
          numVisible: 3,
          numScroll: 3
      },
      {
          breakpoint: '768px',
          numVisible: 2,
          numScroll: 2
      },
      {
          breakpoint: '560px',
          numVisible: 1,
          numScroll: 1
      }
  ];
  }

  handleArticle=(product:any)=>{
    this.router.navigate(["../product"], {relativeTo : this.route , queryParams: {data: JSON.stringify(product)}})
      }
}
