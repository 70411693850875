<div class="blog">
  <div class="header">
    <img class="main-img" src="../../../assets/home/count/bg.png" alt="about-img">
    <div class="imgs-contain">
      <p class="subTitle-img">our products</p>
      <h2 class="title-img">{{translate.data.product.products}}</h2>
    </div>
  </div>
  <div class="content">
    <div class="container">
      <div class="row justify-content-center m-0 productscontaint">
        <p-tabView>
          <p-tabPanel *ngFor="let product of data?.productsCategory"  header= {{product[translate.data.api.name]}} >
            <div class="col-lg-4 col-md-6 col-12" *ngFor="let prod of product?.productsSubs">
              <div class="box">
                <img src={{prod?.imgUrl}} alt="">
                <div class="box-content">
                  <p class="title">{{prod[translate.data.api.title]}}</p>
                  <p class="sub-title">{{prod[translate.data.api.desc]?.slice(0, 150)}}</p>
                  <p class="link"><a (click)="handleArticle(prod)">{{translate.data.blog.more}}</a></p>
                </div>
              </div>
            </div>
          </p-tabPanel>
        </p-tabView>
      </div>
      <!-- <div class="row">
        <div class="col-lg-4 col-md-6 col-12" *ngFor="let product of data?.productsCategory">
          <div class="box">
            <img src={{product?.imgUrl}} alt="">
            <div class="box-content">
              <p class="title">{{product[translate.data.api.maintitle]}}</p>
              <p class="sub-title">{{product[translate.data.api.desc].slice(0, 150)}}</p>
              <p class="link"><a (click)="handleArticle(product)">{{translate.data.blog.more}}</a></p>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>
