import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/shared/translation.service';
import {ActivatedRoute, Router } from '@angular/router';
import {PageService} from '../../page.service';

@Component({
  selector: 'app-products-page',
  templateUrl: './products-page.component.html',
  styleUrls: ['./products-page.component.scss']
})
export class ProductsPageComponent implements OnInit {
  data:any;

  constructor(
    public translate: TranslationService,
    private route: ActivatedRoute,
    private pagesService: PageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.pagesService.getHomeData().subscribe(
      (response: any) => {
        this.data = response;
        console.log("data products",this.data);

      },
      (error) => {
        console.log(error);
      }
    )
  }

  handleArticle=(product:any)=>{
    console.log("product",product);

    this.router.navigate(["../product"], {relativeTo : this.route , queryParams: {data: JSON.stringify(product)}})
      }

}
