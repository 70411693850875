import { Component, OnInit , Input } from '@angular/core';
import { TranslationService } from 'src/app/shared/translation.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {PageService} from '../../../page.service';


@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  @Input() data:any;
  res:any;
  display1:any;

  contactUsForm = new FormGroup({
    'name'           : new FormControl("",Validators.required),
    'emailAddress'   : new FormControl("", Validators.required),
    'subject'        : new FormControl(),
    'message'        : new FormControl("",[Validators.required, Validators.minLength(50) , Validators.maxLength(200)])
   });

  constructor(
    public translate: TranslationService,
    private pagesService: PageService,
  ) { }

  ngOnInit(): void {
  }
  showDialog1() {
    this.display1 = true;
  }

  hideDialog1() {
  this.display1 = false;
  //  this.reloadComponent();
  }

  sendMessage=(contactUsForm:any)=>{
    // this.pagesService.sendContactData(contactUsForm.value).subscribe(
    //   (response: any) => {
    //     this.res = response;
    //     console.log("data", this.res);

    //   },
    //   (error) => {
    //     console.log(error);
    //   }
    // )
    this.showDialog1();
  }
}
