<div class="count">
  <div class="card-contant">
    <div class="contant">
      <p class="num">{{data?.companyMainDetails.projectsCount}}</p>
      <p class="title">{{translate.data.count.check}}</p>
    </div>
    <img src="../../../../assets/home/count/check-mark.svg" alt="">
  </div>
  <div class="card-contant">
    <div class="contant">
      <p class="num">{{data?.companyMainDetails.clientsCount}}</p>
      <p class="title">{{translate.data.count.rating}}</p>
    </div>
    <img src="../../../../assets/home/count/rating.svg" alt="">
  </div>
  <div class="card-contant">
    <div class="contant">
      <p class="num">{{data?.companyMainDetails.prizesCount}}</p>
      <p class="title">{{translate.data.count.medal}}</p>
    </div>
    <img  src="../../../../assets/home/count/medal.svg" alt="">
  </div>
</div>
