import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslationService } from 'src/app/shared/translation.service';
import {PageService} from '../../page.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  res:any;

  data:any;

  logo:any;

  lang:any;

  language:any;

  classTogge:any;

  //navbar collapse
  collapse:any;

  //icons data
  icons:any = ["youtube.svg","Path 44226.svg","instagram.png","linkedin.png"]

  constructor(
    private router: Router,
     private route: ActivatedRoute,
     public translate: TranslationService,
     private pagesService: PageService,
  ) { }

  ngOnInit(): void {
    if(localStorage.getItem("languageApp") === 'en'){
      this.language ='ar' ;
    }else{
      this.language ='en' ;
    }

    this.collapse = "sidenav d-inline";
    if(this.translate.langVar == 'ar'){
      this.lang = "العربية"
    }else{
      this.lang = "English"
    }

    this.classTogge = "d-none";

    this.pagesService.getHomeData().subscribe(
      (response: any) => {
        this.res = response;
        console.log("data", this.data );

      },
      (error) => {
        console.log(error);
      }
    )
  }

  handleBtn(){
    this.router.navigate(["./contactUs"],{relativeTo:this.route});
  }

  handleLang=()=>{
    // this.lang = data
    if(this.language === 'en'){
      localStorage.setItem("languageApp", 'en');
      this.language = 'ar'
    }else{
      localStorage.setItem("languageApp", 'ar');
      this.language = 'en'
    }
    // localStorage.setItem("languageApp", data);
    // console.log(localStorage.getItem('languageApp'));
    location.reload();

  }

  handleDropdown(){
    if(this.classTogge === "d-none"){
      this.classTogge = "d-inline"

    }
    else

      this.classTogge = "d-none"
  }

  handleNavbar=()=>{
    // console.log();

    let element = document.getElementById("navBar");
    element?.classList.toggle("collapsee");
  }



}
