<div class="blog">
  <div class="container">
    <div class="header">
      <p class="sub-title">NEWS & BLOG</p>
      <p class="title">{{translate.data.blog.title}}</p>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-12" *ngFor="let article of data?.blog">
        <div class="box">
          <img src={{article?.imgUrl}} alt="">
          <div class="box-content">
            <p class="title">{{article[translate.data.api.maintitle]}}</p>
            <p class="sub-title">{{article[translate.data.api.desc].slice(0, 150)}}</p>
            <p class="link"><a (click)="handleArticle(article)">{{translate.data.blog.more}}</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
