import { Component, OnInit , Input } from '@angular/core';
import { TranslationService } from 'src/app/shared/translation.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  @Input() data:any;

  constructor(
    public translate: TranslationService,
  ) { }

  ngOnInit(): void {
  }

}
