<div class={{videoClasses}}>
  <video muted loop autoplay src='../../../../assets/home/videoplayback.mp4' id="myVideo" >
  </video>
  <div class="titles">
    <p class="preTitle">{{data?.companyMainDetails[translate.data.api.subtitle]}}</p>
    <h2 class="mainTitle">{{data?.companyMainDetails[translate.data.api.maintitle]}}</h2>
    <!-- <form class=" footer-container">
      <div class="d-flex playaButton">
        <p>{{translate.data.Home.video}}</p>
        <img class="" id="myBtn" src={{videoBtn}} alt="play-button" (click)="handleVideo()">
      </div>

      <button class="btn my-2 my-sm-0" type="submit" (click)="goToContact()">{{translate.data.Home.button}}</button>

    </form> -->
  </div>
</div>
