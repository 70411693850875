<div class="blog">
  <div class="header">
    <img class="main-img" src="../../../assets/home/count/bg.png" alt="about-img">
    <div class="imgs-contain">
      <p class="subTitle-img">NEWS & BLOG</p>
      <h2 class="title-img">{{translate.data.blog.title}}</h2>
    </div>
  </div>
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-12" *ngFor="let article of blog">
          <div class="box">
            <img src={{article?.imgUrl}} alt="">
            <div class="box-content">
              <p class="title">{{article[translate.data.api.maintitle]}}</p>
              <p class="sub-title">{{article[translate.data.api.desc].slice(0, 150)}}</p>
              <p class="link"><a (click)="handleArticle(article)">{{translate.data.blog.more}}</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
