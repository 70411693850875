<div class="footer">
  <div class="row contan">
    <div class="col-lg-6 col-md-6 col-12">
      <div class={{translate.data.footer.logo}}>
        <!-- <img src={{res?.companyMainDetails.imgUrl}} alt=""> -->
        <img src="../../../assets/i.svg" alt="">
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-12">
      <div class="content">
        <p class="phone">{{res?.companyMainDetails.phoneNumber}}</p>
        <p class="title">{{translate.data.footer.text1}}</p>
        <p class="email">{{res?.companyMainDetails.emailAddress}}</p>
        <p class="address">{{res?.companyMainDetails[translate.data.api.address]}}</p>
      </div>
    </div>
  </div>
  <div class="row">
      <ul>
        <li class="nav-item active">
          <a class="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation" routerLink="./home"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}" >{{translate.data.Header.Home}}
                </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation"
           routerLink="about" routerLinkActive="active" >{{translate.data.Header.About}} </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation"
           routerLink="products" routerLinkActive="active" >{{translate.data.footer.products}} </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation"
           routerLink="clientReview" routerLinkActive="active" >{{translate.data.footer.clients}} </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation"
           routerLink="blog" routerLinkActive="active" >{{translate.data.Header.blog}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation" routerLink="contactUs" routerLinkActive="active" >{{translate.data.Header.contactUs}}</a>
        </li>
      </ul>
  </div>
</div>
