
<nav class="navbar mini-menu navbar-expand-lg navbar-light bg-light  d-lg-none d-md-none">
  <div class="container-fluid">
    <a class="" routerLink="./home" routerLinkActive="active"><img class="logo" src="../../../assets/header/logo.svg" alt="icity"></a>
    <button class="navbar-toggler mini-menu-btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <img src="../../../assets/header/Component 100 – 1.svg" alt="" >
    </button>
    <div class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0 my-5">
        <li class="nav-item active">
          <a class="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation" routerLink="./home"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}" (click)="handleNavbar()">{{translate.data.Header.Home}}
                </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation"
           routerLink="about" routerLinkActive="active" (click)="handleNavbar()">{{translate.data.Header.About}} </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation"
           routerLink="blog" routerLinkActive="active" (click)="handleNavbar()">{{translate.data.Header.blog}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation" routerLink="contactUs" routerLinkActive="active" (click)="handleNavbar()">{{translate.data.Header.contactUs}}</a>
        </li>
        <li class="nav-item language">
          <p data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation"  (click)="handleLang()">{{language}}</p>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div class="header">
  <div>
    <a class="" routerLink="./home" routerLinkActive="active"><img class="logo" src={{res?.companyMainDetails.imgUrl}} alt="icity"></a>
  </div>
  <div class="collapsation">
    <img src="../../../assets/header/Component 100 – 1.svg" alt="" (click)="handleNavbar()">
  </div>
  <div class="social-icons">
    <div class="icon">
      <a href={{res?.companyMainDetails.faceBookLink}} target="_blank"><img class="facebook-icon" src="../../../assets/home/socialIcons/Path 44225.svg" alt="facebook" ></a>
    </div>
    <div class="icon" >
      <a href={{res?.companyMainDetails.youtubeLink}} target="_blank"><img src="../../../assets/home/socialIcons/{{icons[0]}}" alt=""></a>
    </div>
    <div class="icon" >
      <a href={{res?.companyMainDetails.twitterLink}} target="_blank"><img src="../../../assets/home/socialIcons/{{icons[1]}}" alt=""></a>
    </div>
    <div class="icon" >
      <a href={{res?.companyMainDetails.instgramLink}} target="_blank"><img src="../../../assets/home/socialIcons/{{icons[2]}}" alt=""></a>
    </div>
    <div class="icon" >
      <a href={{res?.companyMainDetails.linkedInLink}} target="_blank"><img src="../../../assets/home/socialIcons/{{icons[3]}}" alt=""></a>
    </div>
  </div>
</div>
<div class="sidenav" id="navBar">
  <p>{{translate.data.Header.menu}}</p>
  <ul class="navbar-nav">
    <li class="nav-item active">
      <a class="nav-link" routerLink="./home"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}" (click)="handleNavbar()">{{translate.data.Header.Home}}
            </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="about" routerLinkActive="active" (click)="handleNavbar()">{{translate.data.Header.About}} </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="blog" routerLinkActive="active" (click)="handleNavbar()">{{translate.data.Header.blog}}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="contactUs" routerLinkActive="active" (click)="handleNavbar()">{{translate.data.Header.contactUs}}</a>
    </li>
    <li class="nav-item language">
      <p  (click)="handleLang()">{{language}}</p>
    </li>
  </ul>

</div>


