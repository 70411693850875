import { Component, OnInit ,Input } from '@angular/core';
import { TranslationService } from 'src/app/shared/translation.service';
import {ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-first-view',
  templateUrl: './first-view.component.html',
  styleUrls: ['./first-view.component.scss']
})
export class FirstViewComponent implements OnInit {
  //video button
  videoBtn:any;
  videoClasses:any;
  video:any;

  @Input() data:any;

  constructor(
    public translate: TranslationService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.videoBtn= "../../../assets/home/playBtn/Path 44224 (1).svg"
    if(this.translate.langVar == 'ar'){
      this.videoClasses = "videoAr"
    }else{
      this.videoClasses = "videoEn"
    }
    this.video = document.getElementById("myVideo");

  }

   //handle video play and pause
   handleVideo = ()=>{

    let btn = document.getElementById("myBtn");

    if (this.video.paused) {
      this.videoBtn= "../../../assets/home/playBtn/pause-circle-regular.svg"
      this.video.play();
      // btn.innerHTML = "Pause";
    } else {
      this.videoBtn = "../../../assets/home/playBtn/Path 44224 (1).svg"
      this.video.pause();
      // btn.innerHTML = "Play";
    }
  }
  goToContact=()=>{
    this.router.navigate(["../contactUs"],{relativeTo:this.route});
  }

}
