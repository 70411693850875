import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/shared/translation.service';
import {PageService} from '../../page.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  res:any;

  constructor(
    public translate: TranslationService,
    private pagesService: PageService,
  ) { }

  ngOnInit(): void {
    this.pagesService.getHomeData().subscribe(
      (response: any) => {
        this.res = response;


      },
      (error) => {
        console.log(error);
      }
    )
  }

}
