<div class="review">
  <div class="row">
    <div class="col-lg-6 col-12 order-lg-1 order-2">
      <p-carousel [value]="data?.clientsVotings" [circular]="true"  [numVisible]="1" [numScroll]="1">
        <ng-template let-voting pTemplate="item">
          <div class="conteiner">
            <div class="card-containt">
              <img class="img-btn" src="../../../../assets/home/clientReview/Icon.svg" alt="">
              <div class="img-container">
                <img src={{voting?.imgUrl}} alt="">
              </div>
              <div class="contant-container">
                <p class="desc">{{voting[translate.data.api.voting]}}</p>
                <p class="name">{{voting?.name}}</p>
                <p class="job">{{voting?.jobTitle}}</p>
              </div>
            </div>
          </div>
        </ng-template>
      </p-carousel>
    </div>
    <div class="col-lg-6 col-12 order-lg-2 order-1">
      <div class="desc-container">
        <p class="sub-title">TESTIMONIAL</p>
        <h1 class="title">{{translate.data.clients.title}}</h1>
        <p class="des">{{translate.data.clients.moreDesc}}</p>
      </div>
    </div>
  </div>
  <!-- <div class="row clients-container">
    <p-carousel [value]="imgs" [circular]="true"  [numVisible]="5" [numScroll]="1" [responsiveOptions]="responsiveOptions">
      <ng-template let-car pTemplate="item">
        <div class="conteiner">
          <div class="clients">
            <img src="{{'../../../../assets/home/clientReview/logos/' + car}}" alt="">
          </div>
        </div>
      </ng-template>
    </p-carousel>
  </div> -->
</div>
