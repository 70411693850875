<div class="article">
  <div class="header">
    <img class="main-img" src="../../../assets/home/count/bg.png" alt="about-img">
    <div class="imgs-contain">
      <p class="subTitle-img">NEWS & BLOG</p>
      <h2 class="title-img">{{translate.data.blog.title}}</h2>
    </div>
  </div>
  <div class="bodyy">
    <div class="container">
      <div class="row">
        <div class=" edit col-12 d-flex flex-wrap">
          <div class="col-lg-4 col-md-6 col-12">
            <img src={{dataParams?.imgUrl}} alt="">
          </div>
          <div class="col-lg-8 col-md-6 col-12">
            <p class="title">{{dataParams[translate.data.api.maintitle]}}</p>
            <p>{{dataParams[translate.data.api.desc]}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
