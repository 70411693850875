import { Component, OnInit, Input } from '@angular/core';
import { TranslationService } from 'src/app/shared/translation.service';
import {ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-blog-home',
  templateUrl: './blog-home.component.html',
  styleUrls: ['./blog-home.component.scss']
})
export class BlogHomeComponent implements OnInit {

  @Input() data:any;
  blog:Array<any>;

  constructor(
    public translate: TranslationService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.blog = this.data?.blog
  }

  handleArticle=(article)=>{
    this.router.navigate(["../article"], {relativeTo : this.route , queryParams: {data: JSON.stringify(article)}})
      }

}
