import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { BlogComponent } from './pages/blog/blog.component';
import { ArticleComponent } from './pages/article/article.component';
import { ProductsPageComponent } from './pages/products-page/products-page.component';
import { ReviewComponent } from './pages/home/review/review.component';
import { ProductPageComponent } from './pages/product-page/product-page.component';




const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'home', component: HomeComponent},
  {path: 'about', component: AboutComponent},
  {path: 'contactUs', component: ContactUsComponent},
  {path: 'blog', component: BlogComponent},
  {path: 'article', component:ArticleComponent},
  {path: 'products', component:ProductsPageComponent},
  {path: 'clientReview', component:ReviewComponent},
  {path: 'product', component:ProductPageComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
