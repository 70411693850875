import { Component, OnInit  } from '@angular/core';
import { TranslationService } from 'src/app/shared/translation.service';
import {ActivatedRoute, Router , Params } from '@angular/router';

@Component({
  selector: 'app-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.scss']
})
export class ProductPageComponent implements OnInit {

  dataParams:any;

  constructor(
    public translate: TranslationService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      (params: Params) => {
        this.dataParams = JSON.parse(params['data']);
    })
  }

}
