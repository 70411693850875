import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  ipRoot: string = 'https://icityindustrial.icitybackend.com/api/v1';

  constructor() { }
}
