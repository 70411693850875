import { HttpClient } from '@angular/common/http';
import { SharedService } from './shared/shared.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  constructor(
    private sharedService: SharedService,
    private http: HttpClient
  ) { }

  // get home data
  getHomeData(){
    return this.http.get(this.sharedService.ipRoot + '/Home')
  }

  // contact us
  sendContactData(data:any){
    return this.http.post(this.sharedService.ipRoot + '/ContactUsRequests' , data)
  }
}
