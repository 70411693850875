<div class="about">
  <div class="aboutus">
    <div class="row">
      <div class="col-lg-6">
        <div class="img-container">
          <img src={{data?.companyMainDetails.aboutUs_Image}} alt="">
        </div>
      </div>
      <div class="col-lg-6">
        <div class="content-container">
          <h1 class="sub-title">{{translate.data.aboutus.subTitle}}</h1>
          <p class="title">{{translate.data.aboutus.title}}</p>
          <p class="desc">{{data?.companyMainDetails[translate.data.api.aboutus]}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="second">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12 my-3">
          <h2 class="title">{{translate.data.aboutus.mission}}</h2>
          <p class="desc">{{data?.companyMainDetails[translate.data.api.mission]}}</p>
        </div>
        <div class="col-lg-6 col-md-6 col-12 my-3">
          <h2 class="title">{{translate.data.aboutus.Vision}}</h2>
          <p class="desc">{{data?.companyMainDetails[translate.data.api.vision]}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
