import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {PageService} from '../../page.service';
import { TranslationService } from 'src/app/shared/translation.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  data:any;

  constructor(
    private route: ActivatedRoute,
    private pagesService: PageService,
    private router: Router,
    public translate: TranslationService,
  ) { }

  ngOnInit(): void {
    this.pagesService.getHomeData().subscribe(
      (response: any) => {
        this.data = response;
        console.log("data", this.data );

      },
      (error) => {
        console.log(error);
      }
    )
  }

}
