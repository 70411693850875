import { Component, OnInit, Input } from '@angular/core';
import { TranslationService } from 'src/app/shared/translation.service';

@Component({
  selector: 'app-count',
  templateUrl: './count.component.html',
  styleUrls: ['./count.component.scss']
})
export class CountComponent implements OnInit {

  @Input() data:any;

  constructor(
    public translate: TranslationService,
  ) { }

  ngOnInit(): void {
  }

}
