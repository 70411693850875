<div class="about">
  <div class="row">
    <div class="col-lg-6">
      <div class="img-container">
        <img src={{data?.companyMainDetails.aboutUs_Image}} alt="">
      </div>
    </div>
    <div class="col-lg-6">
      <div class="content-container">
        <h1 class="sub-title">{{translate.data.aboutus.subTitle}}</h1>
        <p class="title">{{translate.data.aboutus.title}}</p>
        <p class="desc">{{data?.companyMainDetails[translate.data.api.aboutus]}}</p>
        <button>{{translate.data.aboutus.more}}</button>
      </div>
    </div>
  </div>
</div>
