import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslationService } from './shared/translation.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { CommonModule } from '@angular/common';


import { SharedModule } from './shared/shared.module';
import { SharedService } from './shared/shared.service';
import { RouterModule  } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoaderComponent } from './components/loader/loader.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HomeComponent } from './pages/home/home.component';
import { FirstViewComponent } from './pages/home/first-view/first-view.component';
import { AboutUsComponent } from './pages/home/about-us/about-us.component';
import { ProductsComponent } from './pages/home/products/products.component';

import {CarouselModule} from 'primeng/carousel';
import {DialogModule} from 'primeng/dialog';
import {TabViewModule} from 'primeng/tabview';
import { CountComponent } from './pages/home/count/count.component';
import { ReviewComponent } from './pages/home/review/review.component';
import { OrderComponent } from './pages/home/order/order.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { BlogComponent  } from './pages/blog/blog.component';
import { ArticleComponent } from './pages/article/article.component';
import { ProductPageComponent } from './pages/product-page/product-page.component';
import { ProductsPageComponent } from './pages/products-page/products-page.component';
import { BlogHomeComponent } from './pages/home/blog-home/blog-home.component';



// set english language is the main lang in app
export function setupTranslateFactory(
  service: TranslationService): Function {
  return () =>  service.use('ar');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    PageNotFoundComponent,
    HomeComponent,
    FirstViewComponent,
    AboutUsComponent,
    ProductsComponent,
    CountComponent,
    ReviewComponent,
    OrderComponent,
    BlogComponent,
    AboutComponent,
    ContactUsComponent,
    ArticleComponent,
    ProductPageComponent,
    ProductsPageComponent,
    BlogHomeComponent
  ],
  imports: [
    BrowserModule,

    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    RouterModule,
    CarouselModule,
    TabViewModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    RouterModule.forRoot(
      [

      ],{scrollPositionRestoration: 'enabled'}
    ),

  ],
  providers: [
    SharedService,
    TranslationService,
    {
     provide: APP_INITIALIZER,
     useFactory: setupTranslateFactory,
     deps: [ TranslationService ],
     multi: true
   },
   {provide: LocationStrategy, useClass:  HashLocationStrategy},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
