import { Component, OnInit, Input } from '@angular/core';
import { TranslationService } from 'src/app/shared/translation.service';
import {ActivatedRoute, Router } from '@angular/router';
import {PageService} from '../../../page.service';


@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {
  @Input() data:any;
  articles:Array<any> = [1,2,3]
  imgs:Array<any> = ["1.png","2.png","3.png","4.png","5.png"];
  responsiveOptions:any;

  constructor(
    public translate: TranslationService,
    private route: ActivatedRoute,
    private pagesService: PageService,
    private router: Router

  ) { }

  ngOnInit(): void {
    this.pagesService.getHomeData().subscribe(
      (response: any) => {
        this.data = response;
        console.log("data", this.data );

      },
      (error) => {
        console.log(error);
      }
    )

    this.responsiveOptions = [
      {
          breakpoint: '1024px',
          numVisible: 4,
          numScroll: 3
      },
      {
          breakpoint: '768px',
          numVisible: 3,
          numScroll: 2
      },
      {
          breakpoint: '560px',
          numVisible: 1,
          numScroll: 1
      }
  ];
  }

}
