import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/shared/translation.service';
import {ActivatedRoute, Router } from '@angular/router';
import {PageService} from '../../page.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  data:any;
  blog:Array<any>;
  list:Array<any> = [1,2,3];


  constructor(
    public translate: TranslationService,
    private route: ActivatedRoute,
    private pagesService: PageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.pagesService.getHomeData().subscribe(
      (response: any) => {
        this.data = response;
        this.blog = this.data.blog;
        console.log("data", this.blog );

      },
      (error) => {
        console.log(error);
      }
    )
  }

  handleArticle=(article)=>{
    this.router.navigate(["../article"], {relativeTo : this.route , queryParams: {data: JSON.stringify(article)}})
      }

}
