<div class="products">
  <div class="header">
    <p class="title">our products</p>
    <h1 class="sub-title">{{translate.data.product.products}}</h1>
  </div>
  <div class="row justify-content-center m-0 products-containt">
    <p-tabView>
      <p-tabPanel *ngFor="let product of data?.productsCategory"  header= {{product[translate.data.api.name]}} >

            <div class="container">
              <div class="row">
                    <p-carousel [value]="product?.productsSubs" [circular]="true" [autoplayInterval]="3000" [numVisible]="2" [numScroll]="1" [responsiveOptions]="responsiveOptions">
                      <ng-template let-product pTemplate="item">
                        <div class="conteiner">
                          <div class="card-containt">
                            <img class="img" src={{product?.imgUrl}} alt="">
                            <div class="desc">
                              <p class="title">{{product[translate.data.api.title]}}</p>
                              <div class="line"></div>
                              <p class="descreption">{{product[translate.data.api.desc].slice(0,100)}}</p>
                              <div class="image">
                                <img (click)="handleArticle(product)" src="../../../../assets/home/products/Group 10825.svg" alt="">
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </p-carousel>

              </div>
            </div>

      </p-tabPanel>
    </p-tabView>
  </div>
</div>
