<div class="order">
  <div class="content">
    <div class="header">
      <p class="sub-title">GET A QUOTE</p>
      <p class="title">{{translate.data.service.order}}</p>
    </div>
    <form [formGroup]="contactUsForm" class="cotaant-container">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <input formControlName="name" type="text" placeholder={{translate.data.service.name}}>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <input formControlName="emailAddress" type="email" placeholder={{translate.data.service.email}}>
        </div>
        <div class="col-12">
          <input formControlName="subject" type="text" placeholder={{translate.data.service.subject}}>
        </div>
        <div class="col-12">
          <textarea formControlName="message" placeholder={{translate.data.service.mesg}} rows="4" cols="50"></textarea>
        </div>
      </div>
      <div class="btn-container">
        <button (click)="sendMessage(contactUsForm)">{{translate.data.service.send}}</button>
      </div>
    </form>
  </div>
</div>


<!-- ---------------------------------------- modal --------------------------------------- -->

<p-dialog  [(visible)]="display1" contentStyleClass="mydialog" [closable]="false" >
  <div class="send-container">
    <img src="../../../assets/send/send.svg" alt="send">
    <h2>hhhhhhhhhhh</h2>
    <p>jjjjjjjjjjjj</p>
    <div class="send-btns">
      <button class="btn1" >hhhhhhhhh</button>
      <button class="btn2" >hhhhhhhhhh</button>
    </div>
  </div>
  </p-dialog>
