<div class="contact">
  <div class="container">
    <img class={{translate.data.contact.class}} src="../../../assets/contact/robot-with-nuts-bolts.png" alt="">
    <div class="row">
      <div class="col-lg-7 col-md-7 col-12">
        <h1 class="title">{{translate.data.contact.title}}</h1>
        <p class="sub-title">{{translate.data.contact.question}}</p>
        <form [formGroup]="contactUsForm" class="cotaant-container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
              <input formControlName="name" type="text" placeholder={{translate.data.service.name}}>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
              <input formControlName="emailAddress" type="email" placeholder={{translate.data.service.email}}>
            </div>
            <div class="col-12">
              <input formControlName="subject" type="text" placeholder={{translate.data.service.subject}}>
            </div>
            <div class="col-12">
              <textarea formControlName="message" placeholder={{translate.data.service.mesg}} rows="4" cols="50"></textarea>
            </div>
          </div>
          <div class="btn-container">
            <button (click)="sendMessage(contactUsForm)">{{translate.data.service.send}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
